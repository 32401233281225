html, body, #root  {
    height: 100%;
}

.loader, .error {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
