@charset "UTF-8";
$bootstrap-sass-asset-helper: false !default;

// Variables and Mixins
@import "~infobip-bootstrap/src/styles/styleguide/variables";
@import "~infobip-bootstrap/src/styles/styleguide/mixins";

// Normalize
@import "~infobip-bootstrap/src/styles/styleguide/normalize";

// Fonts
@import "~infobip-bootstrap/src/styles/styleguide/fonts";

@import "~@infobip/bepo-tokens/scss/brand";
@import "~@infobip/bepo-tokens/scss/global";

// FIX of missing BEPO styles in builded app
*,:after,:before{box-sizing:inherit}
html{box-sizing:border-box;font-family:Roboto,sans-serif}

@import "components";
