@import "~infobip-knowledge-base-web-shared/article-styles/article-shared-styles-mixin";

$sidebar-container-width: 332px;

.page {
  display: flex;
  justify-content: center;
  min-height: 100%;

  .page-wrapper {
    flex: 0 0 calc(100% - 16px);
    overflow: hidden;
    background: $b2-color-wind-500;

    .page-content-with-sidebar {
      display: flex;
      flex-direction: column;
    }

    .page-content {
      padding: 100px 32px 40px;

      .logo-container {
        height: 100px;
        margin-top: -100px;
        display: flex;

        .logo {
          max-height: 60px;
          align-self: center;
        }
      }
    }

    .sidebar {
      padding: 8px 32px 40px;
      margin: 0;
      overflow-x: hidden;

      h5 {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }

      .attachments {
        margin-top: 19px;
        > div {
          width: 100%;
        }
      }
    }

    .article, .attachment-download {
      @include article-shared-styles-view();
      font-size: 14px !important;
      word-break: break-word;

      .article-header {
        margin-top: 0;
      }
    }
  }
}

@media screen and (min-width: 768px) { // tablet
  .page .page-wrapper {
    flex-basis: 688px;

    .page-content {
      padding: 100px 64px 40px;
    }
    .sidebar {
      padding: 32px 64px 40px;
    }
  }
}

@media screen and (min-width: 1040px) {
  .page {
    .page-wrapper {
      flex-basis: 1024px;

      .page-content-with-sidebar {
        flex-direction: row;
        min-height: 100%;
        align-items: stretch;
      }

      .page-content {
        flex: 1 0;
      }

      .sidebar {
        padding: 107px 16px 40px;
        flex: 0 0 $sidebar-container-width;
        border-left: 1px solid $b2-color-stone-400;
      }
    }
  }
}
